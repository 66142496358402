export const orderStatus: {
  code: string;
  value: string;
  color: string;
}[] = [
  {
    code: "WITH_ERRORS",
    value: "Con errores",
    color: "red",
  },
  {
    code: "CREATED",
    value: "Creada",
    color: "blue",
  },
  {
    code: "PAYMENT_PENDING",
    value: "Pendiente de pago",
    color: "yellow",
  },
  {
    code: "IN_PROCESS",
    value: "Procesando",
    color: "blue",
  },
  {
    code: "BILLED",
    value: "Facturada",
    color: "green",
  },
  {
    code: "CANCELLED",
    value: "Cancelada",
    color: "red",
  },
  {
    code: "REFUNDED",
    value: "Reembolsada",
    color: "yellow",
  },
  {
    code: "COMPLETED",
    value: "Completada",
    color: "green",
  },
  {
    code: "IN_TRANSIT",
    value: "En tránsito",
    color: "blue",
  },
  {
    code: "DELIVERED",
    value: "Entregada",
    color: "green",
  },
];

export const customShops = [
  {
    slug: "gelato",
    href: "https://elgelatooficial.com",
  },
  {
    slug: "mycesta",
    href: "https://mycesta.com",
  },
  {
    slug: "celebren-mercadito",
    href: "https://celebrenstore.com/mercadito",
  },
  {
    slug: "celebren-tienda",
    href: "https://celebrenstore.com/tienda",
  },
  {
    slug: "celebren-studio",
    href: "https://celebrenstore.com/estudio",
  },
  {
    slug: "somarriba",
    href: "https://celebrenstore.com/somarriba",
  },
  {
    slug: "centro-elaboracion2",
    href: "https://mayoristas.grupoacena.com",
  },
];

export const availableBusiness: { id: number; slug: string }[] = [
  {
    id: 14,
    slug: "acena",
  },
  {
    id: 232,
    slug: "mercado-multimas",
  },
  {
    id: 308,
    slug: "tres-reyes",
  },
  {
    id: 127,
    slug: "producciones-leon-diaz",
  },
  {
    id: 2,
    slug: "zapyapizza",
  },
  {
    id: 13,
    slug: "doctoragalletica",
  },
  {
    id: 151,
    slug: "el-chino",
  },
  {
    id: 6,
    slug: "tecopos",
  },
  {
    id: 261,
    slug: "sucursal-ssp",
  },
  {
    id: 296,
    slug: "centurion",
  },
  {
    id: 50,
    slug: "alba",
  },
  {
    id: 19,
    slug: "mrchino",
  },
  {
    id: 109,
    slug: "gelato-oficial",
  },
  {
    id: 42,
    slug: "tecopos-contabilidad",
  },
  {
    id: 1,
    slug: "kopas",
  },
  {
    id: 3,
    slug: "fotosmahe",
  },
  {
    id: 57,
    slug: "yusbel",
  },
  {
    id: 121,
    slug: "la-diferencia-cancún",
  },
  {
    id: 231,
    slug: "tu-tienda",
  },
  {
    id: 10,
    slug: "almaelectrica",
  },
  {
    id: 252,
    slug: "el-nino-de-atocha",
  },
  {
    id: 230,
    slug: "casa-matriz-azulita",
  },
  {
    id: 77,
    slug: "rosa-del-carmen",
  },
  {
    id: 221,
    slug: "dolores",
  },
  {
    id: 331,
    slug: "Juan-ricabrog",
  },
  {
    id: 152,
    slug: "house-electrodomesticos",
  },
  {
    id: 157,
    slug: "Casa matriz 78",
  },
  {
    id: 15,
    slug: "puromani_tita_y_tito",
  },
  {
    id: 7,
    slug: "bazzar",
  },
  {
    id: 16,
    slug: "sweetsalad",
  },
  {
    id: 332,
    slug: "dulce",
  },
  {
    id: 293,
    slug: "aida-minimarket",
  },
  {
    id: 226,
    slug: "la-villita",
  },
  {
    id: 204,
    slug: "grupo-cea",
  },
  {
    id: 203,
    slug: "donaciones",
  },
  {
    id: 312,
    slug: "la-faraona",
  },
  {
    id: 4,
    slug: "loschinospizza",
  },
  {
    id: 304,
    slug: "prueba",
  },
  {
    id: 153,
    slug: "walking-shop",
  },
  {
    id: 131,
    slug: "maz-yan",
  },
  {
    id: 162,
    slug: "mercado112",
  },
  {
    id: 82,
    slug: "lyn-arte-grafico",
  },
  {
    id: 11,
    slug: "esquinajorge",
  },
  {
    id: 145,
    slug: "hostal-torres",
  },
  {
    id: 78,
    slug: "eduardo",
  },
  {
    id: 40,
    slug: "kingdom",
  },
  {
    id: 56,
    slug: "dulceria-rancel",
  },
  {
    id: 309,
    slug: "king-kong",
  },
  {
    id: 35,
    slug: "acenna-26",
  },
  {
    id: 234,
    slug: "jaimanita",
  },
  {
    id: 190,
    slug: "siempre-tu-y-yo",
  },
  {
    id: 69,
    slug: "gd-shop",
  },
  {
    id: 32,
    slug: "la-de-la-fabrica",
  },
  {
    id: 37,
    slug: "mamis-empoderadas",
  },
  {
    id: 118,
    slug: "las-magnolias",
  },
  {
    id: 36,
    slug: "el-universitario",
  },
  {
    id: 83,
    slug: "dayan",
  },
  {
    id: 228,
    slug: "la-flora",
  },
  {
    id: 86,
    slug: "32y7ma",
  },
  {
    id: 18,
    slug: "juanky-dj-ayuda",
  },
  {
    id: 214,
    slug: "choco-vie",
  },
  {
    id: 335,
    slug: "casa-matriz-noel",
  },
  {
    id: 222,
    slug: "hacienda-el-patron",
  },
  {
    id: 216,
    slug: "reposteria-cardenas",
  },
  {
    id: 201,
    slug: "omar",
  },
  {
    id: 5,
    slug: "verdeverde",
  },
  {
    id: 24,
    slug: "the-big-family",
  },
  {
    id: 236,
    slug: "y&y",
  },
  {
    id: 233,
    slug: "casa-matriz-pichy",
  },
  {
    id: 340,
    slug: "casamatriz-habitex",
  },
  {
    id: 220,
    slug: "restaurante-gelato",
  },
  {
    id: 219,
    slug: "cafe-armonía",
  },
  {
    id: 196,
    slug: "market-home",
  },
  {
    id: 195,
    slug: "las-palmitas",
  },
  {
    id: 100,
    slug: "restaurante-panbarca",
  },
  {
    id: 150,
    slug: "moneda-cubana-salon",
  },
  {
    id: 158,
    slug: "Mata Hambres",
  },
  {
    id: 99,
    slug: "casa-matriz-osvel",
  },
  {
    id: 194,
    slug: "la-pera",
  },
  {
    id: 192,
    slug: "tienda-belleza-exclusiva",
  },
  {
    id: 338,
    slug: "el-clave-506",
  },
  {
    id: 197,
    slug: "anabella-shop",
  },
  {
    id: 333,
    slug: "Habitex",
  },
  {
    id: 217,
    slug: "bazar-la-varilla",
  },
  {
    id: 199,
    slug: "isabella-shop",
  },
  {
    id: 336,
    slug: "kiki",
  },
  {
    id: 187,
    slug: "alborada",
  },
  {
    id: 334,
    slug: "lukas-pan",
  },
  {
    id: 156,
    slug: "pacubamas",
  },
  {
    id: 339,
    slug: "100-y-51",
  },
  {
    id: 267,
    slug: "keishop-pyme-tienda",
  },
  {
    id: 124,
    slug: "Dalis Supermarket",
  },
  {
    id: 215,
    slug: "la-burbuja",
  },
  {
    id: 185,
    slug: "lo-d-yoary",
  },
  {
    id: 135,
    slug: "vakanegra-casa-matriz",
  },
  {
    id: 209,
    slug: "centro-elaboracion",
  },
  {
    id: 238,
    slug: "casa-matriz-laura",
  },
  {
    id: 273,
    slug: "don-sabroso",
  },
  {
    id: 224,
    slug: "luis-daniel",
  },
  {
    id: 186,
    slug: "el-pestanazo",
  },
  {
    id: 133,
    slug: "servicios",
  },
  {
    id: 60,
    slug: "celebren-tienda",
  },
  {
    id: 55,
    slug: "don-papa",
  },
  {
    id: 53,
    slug: "la-diferencia",
  },
  {
    id: 44,
    slug: "keishop-casa-matriz",
  },
  {
    id: 38,
    slug: "mercadito-112",
  },
  {
    id: 245,
    slug: "almacen-picky-pan",
  },
  {
    id: 210,
    slug: "juegos-megapixel",
  },
  {
    id: 34,
    slug: "fabrica-2",
  },
  {
    id: 239,
    slug: "centro-elaboracion-laura",
  },
  {
    id: 218,
    slug: "reposteria-santiago",
  },
  {
    id: 33,
    slug: "fabrica-1",
  },
  {
    id: 28,
    slug: "big-family-10octubre",
  },
  {
    id: 43,
    slug: "el-gallo",
  },
  {
    id: 89,
    slug: "mercado-loshermanos",
  },
  {
    id: 270,
    slug: "mundo-soft",
  },
  {
    id: 272,
    slug: "charcuteria-jhonnie",
  },
  {
    id: 271,
    slug: "mas-que-pan",
  },
  {
    id: 254,
    slug: "el-gallo-casa-matriz",
  },
  {
    id: 202,
    slug: "casa-matriz-yoarys",
  },
  {
    id: 87,
    slug: "pupus-crack-casamatriz",
  },
  {
    id: 255,
    slug: "pollito-25",
  },
  {
    id: 191,
    slug: "cafeteria-la-panchita",
  },
  {
    id: 235,
    slug: "grupo-comercial-america",
  },
  {
    id: 200,
    slug: "anabella-linea",
  },
  {
    id: 249,
    slug: "la-nena",
  },
  {
    id: 164,
    slug: "axari",
  },
  {
    id: 248,
    slug: "casa-matriz-siempre-tu-y-yo",
  },
  {
    id: 247,
    slug: "mercado-siempre-y-yo",
  },
  {
    id: 163,
    slug: "bodegon-cordova",
  },
  {
    id: 179,
    slug: "la-primera-de-san-francisco",
  },
  {
    id: 178,
    slug: "celebren-studio",
  },
  {
    id: 116,
    slug: "business-monica",
  },
  {
    id: 92,
    slug: "la-roca",
  },
  {
    id: 67,
    slug: "cogelotuyo",
  },
  {
    id: 280,
    slug: "casa-matriz-el-moro",
  },
  {
    id: 274,
    slug: "casamatriz-dariel",
  },
  {
    id: 259,
    slug: "las-canchas",
  },
  {
    id: 66,
    slug: "mercadito-neptuno",
  },
  {
    id: 48,
    slug: "keishop-nueva-plaza",
  },
  {
    id: 341,
    slug: "casamatriz-dulcecitos",
  },
  {
    id: 291,
    slug: "gallo-tcp",
  },
  {
    id: 122,
    slug: "vak_negra",
  },
  {
    id: 257,
    slug: "mendoza",
  },
  {
    id: 290,
    slug: "delicias-de-la-abuela-lily",
  },
  {
    id: 289,
    slug: "mini-market",
  },
  {
    id: 287,
    slug: "thaly-entrepelos",
  },
  {
    id: 288,
    slug: "casa-matriz-la-pera",
  },
  {
    id: 256,
    slug: "mercadito-30",
  },
  {
    id: 343,
    slug: "dulcecitos-colon",
  },
  {
    id: 96,
    slug: "oshedi-las-flores",
  },
  {
    id: 117,
    slug: "casa-matriz-osheidi",
  },
  {
    id: 119,
    slug: "oshedi-3era-70",
  },
  {
    id: 143,
    slug: "la-villa",
  },
  {
    id: 284,
    slug: "mercadito-23-26",
  },
  {
    id: 292,
    slug: "k&kbaby",
  },
  {
    id: 268,
    slug: "la-casita",
  },
  {
    id: 276,
    slug: "mercadito-industria ",
  },
  {
    id: 282,
    slug: "lanservi-vcl",
  },
  {
    id: 108,
    slug: "gg-mercado2",
  },
  {
    id: 298,
    slug: "dubaidubai",
  },
  {
    id: 47,
    slug: "keishop-acuario",
  },
  {
    id: 344,
    slug: "areaproduccion-dulcecitos",
  },
  {
    id: 301,
    slug: "acena-panaderia2",
  },
  {
    id: 142,
    slug: "fragadora-dekars",
  },
  {
    id: 302,
    slug: "entredos",
  },
  {
    id: 342,
    slug: "ducecitos-casinodeportivo",
  },
  {
    id: 337,
    slug: "la-tijera",
  },
  {
    id: 21,
    slug: "acenna-la-lisa",
  },
  {
    id: 299,
    slug: "casa-matriz-marquesina",
  },
  {
    id: 300,
    slug: "el-matadero",
  },
  {
    id: 297,
    slug: "aitalex-nave",
  },
  {
    id: 295,
    slug: "mercado-4-caminos",
  },
  {
    id: 149,
    slug: "moneda-cubana-cm",
  },
  {
    id: 148,
    slug: "la-espanola",
  },
  {
    id: 294,
    slug: "almacen-52",
  },
  {
    id: 306,
    slug: "casa-matriz-3-reyes",
  },
  {
    id: 311,
    slug: "mercado-chiringuito",
  },
  {
    id: 310,
    slug: "monte",
  },
  {
    id: 286,
    slug: "one-service",
  },
  {
    id: 307,
    slug: "centro-produccion-3-reyes",
  },
  {
    id: 277,
    slug: "triple-b",
  },
  {
    id: 260,
    slug: "parque-pescado",
  },
  {
    id: 223,
    slug: "la-matematica",
  },
  {
    id: 208,
    slug: "comercial-ninito",
  },
  {
    id: 184,
    slug: "nautico-laespecial",
  },
  {
    id: 305,
    slug: "triple-m",
  },
  {
    id: 193,
    slug: "tienda-calle-blanco",
  },
  {
    id: 314,
    slug: "jewels-ocean",
  },
  {
    id: 188,
    slug: "casa-matriz-infinito",
  },
  {
    id: 183,
    slug: "la-especial",
  },
  {
    id: 182,
    slug: "casa-matriz-la-especial",
  },
  {
    id: 125,
    slug: "el-klave",
  },
  {
    id: 181,
    slug: "tati-la-belle",
  },
  {
    id: 180,
    slug: "cafeteria-la-rosa",
  },
  {
    id: 176,
    slug: "dulce-vida",
  },
  {
    id: 258,
    slug: "mercado-3l",
  },
  {
    id: 313,
    slug: "gerardo-peaky",
  },
  {
    id: 283,
    slug: "confituras-lisi",
  },
  {
    id: 281,
    slug: "el-morro-33",
  },
  {
    id: 279,
    slug: "bazar-bambu",
  },
  {
    id: 278,
    slug: "bodegon-100",
  },
  {
    id: 316,
    slug: "sandalo",
  },
  {
    id: 269,
    slug: "chaviano",
  },
  {
    id: 266,
    slug: "candy-shop",
  },
  {
    id: 205,
    slug: "cea-grupo",
  },
  {
    id: 275,
    slug: "jamones-del-rancho-acosta",
  },
  {
    id: 265,
    slug: "peaky-pan-sevillano",
  },
  {
    id: 264,
    slug: "peaky-pan-vento",
  },
  {
    id: 317,
    slug: "bodegon-rosa",
  },
  {
    id: 263,
    slug: "piscina-nuevo",
  },
  {
    id: 262,
    slug: "bodeguita-martinez",
  },
  {
    id: 198,
    slug: "casa-matriz-anabella",
  },
  {
    id: 315,
    slug: "casa-matriz-ocean",
  },
  {
    id: 253,
    slug: "peaky-pan-3",
  },
  {
    id: 251,
    slug: "almacen-peaky-pan",
  },
  {
    id: 250,
    slug: "peaky-pan",
  },
  {
    id: 246,
    slug: "picky-pan-4ta",
  },
  {
    id: 242,
    slug: "casa-matriz-rancho",
  },
  {
    id: 240,
    slug: "rancho-vento-2",
  },
  {
    id: 237,
    slug: "rancho-vento",
  },
  {
    id: 227,
    slug: "las-yagrumas",
  },
  {
    id: 225,
    slug: "8-via",
  },
  {
    id: 213,
    slug: "merkadito",
  },
  {
    id: 212,
    slug: "la-campana-ninito",
  },
  {
    id: 211,
    slug: "casa-matriz-ninito",
  },
  {
    id: 207,
    slug: "hamburguesa-cortina",
  },
  {
    id: 206,
    slug: "almacen-lisa-roger",
  },
  {
    id: 189,
    slug: "infinito-guinera",
  },
  {
    id: 175,
    slug: "casa-ruiz",
  },
  {
    id: 160,
    slug: "kedetalles",
  },
  {
    id: 177,
    slug: "somarriba",
  },
  {
    id: 52,
    slug: "mercadito-kholy",
  },
  {
    id: 51,
    slug: "mercado-mayorista",
  },
  {
    id: 46,
    slug: "la-taberna",
  },
  {
    id: 45,
    slug: "keishop-tienda",
  },
  {
    id: 41,
    slug: "dulceria-tu-me-gustas",
  },
  {
    id: 39,
    slug: "el-duende",
  },
  {
    id: 31,
    slug: "de-todo-un-poco",
  },
  {
    id: 30,
    slug: "big-family-26-2",
  },
  {
    id: 29,
    slug: "big-family-26-1",
  },
  {
    id: 27,
    slug: "big-family-16",
  },
  {
    id: 23,
    slug: "acenna-santa-catalina",
  },
  {
    id: 22,
    slug: "acenna-playa",
  },
  {
    id: 20,
    slug: "acenna-centro-produccion",
  },
  {
    id: 12,
    slug: "capricho",
  },
  {
    id: 8,
    slug: "bazarmusa",
  },
  {
    id: 49,
    slug: "gelato",
  },
  {
    id: 229,
    slug: "la-azulita-playa",
  },
  {
    id: 174,
    slug: "merkadeli",
  },
  {
    id: 173,
    slug: "casa-matriz-roger",
  },
  {
    id: 172,
    slug: "akokan",
  },
  {
    id: 171,
    slug: "bodegon-idais",
  },
  {
    id: 166,
    slug: "kedetalles-arte-grafico",
  },
  {
    id: 105,
    slug: "kluniversal",
  },
  {
    id: 106,
    slug: "ggmayorista",
  },
  {
    id: 167,
    slug: "taller-dolores-22",
  },
  {
    id: 155,
    slug: "market-fontanar",
  },
  {
    id: 154,
    slug: "ruli-casa-matriz",
  },
  {
    id: 147,
    slug: "que-locura",
  },
  {
    id: 141,
    slug: "casa-matriz-yasmany",
  },
  {
    id: 243,
    slug: "casa-matriz-leom",
  },
  {
    id: 244,
    slug: "leon-2",
  },
  {
    id: 126,
    slug: "charcuteria-leon",
  },
  {
    id: 84,
    slug: "32y7ma",
  },
  {
    id: 132,
    slug: "centro-elaboracion2",
  },
  {
    id: 120,
    slug: "casa-matriz-la-diferencia",
  },
  {
    id: 110,
    slug: "tienda-cubana",
  },
  {
    id: 104,
    slug: "casa-matriz-andy",
  },
  {
    id: 103,
    slug: "casa-matriz-cafemiel",
  },
  {
    id: 102,
    slug: "casamatriz-jonny",
  },
  {
    id: 101,
    slug: "cortina",
  },
  {
    id: 98,
    slug: "bodegon-medina",
  },
  {
    id: 95,
    slug: "market-siboney",
  },
  {
    id: 94,
    slug: "ventas",
  },
  {
    id: 91,
    slug: "havana-shop",
  },
  {
    id: 90,
    slug: "johnnie-mercadito",
  },
  {
    id: 85,
    slug: "cafe-miel",
  },
  {
    id: 93,
    slug: "centro-produccion",
  },
  {
    id: 97,
    slug: "casa-matriz-el-gelato",
  },
  {
    id: 170,
    slug: "d-maria",
  },
  {
    id: 169,
    slug: "el-chiringuito",
  },
  {
    id: 26,
    slug: "big-family-bointa",
  },
  {
    id: 25,
    slug: "big-family-poderosa",
  },
  {
    id: 329,
    slug: "azulita-al-x-mayor",
  },
  {
    id: 71,
    slug: "saumel-correasyretenes",
  },
  {
    id: 70,
    slug: "saumel-casa-matriz",
  },
  {
    id: 285,
    slug: "Pruebas",
  },
  {
    id: 88,
    slug: "cafeteria-elbatido",
  },
  {
    id: 137,
    slug: "vakanegra-ss",
  },
  {
    id: 136,
    slug: "leandro-casa-matriz",
  },
  {
    id: 130,
    slug: "servicios-logisticos-el-bello",
  },
  {
    id: 129,
    slug: "base-productiva-el-bello",
  },
  {
    id: 128,
    slug: "one",
  },
  {
    id: 303,
    slug: "solera",
  },
  {
    id: 330,
    slug: "mk-market",
  },
  {
    id: 168,
    slug: "piscina-la-curva",
  },
  {
    id: 165,
    slug: "casamatriz-kedetalles",
  },
  {
    id: 159,
    slug: "exitos-b",
  },
  {
    id: 81,
    slug: "charcuteria-cuervos",
  },
  {
    id: 80,
    slug: "cuervos-casa-matriz",
  },
  {
    id: 72,
    slug: "suarez-serrano",
  },
  {
    id: 161,
    slug: "infinito",
  },
  {
    id: 79,
    slug: "azulita",
  },
  {
    id: 76,
    slug: "reinier",
  },
  {
    id: 75,
    slug: "esmeralda",
  },
  {
    id: 74,
    slug: "maria-elena",
  },
  {
    id: 73,
    slug: "dinia",
  },
  {
    id: 68,
    slug: "mycesta",
  },
  {
    id: 64,
    slug: "qmd-cotorro",
  },
  {
    id: 61,
    slug: "celebren-mercadito",
  },
  {
    id: 59,
    slug: "matriz-celebren",
  },
  {
    id: 58,
    slug: "dartacan-41",
  },
  {
    id: 54,
    slug: "fragadora",
  },
  {
    id: 65,
    slug: "pan-barca",
  },
  {
    id: 62,
    slug: "qmd",
  },
  {
    id: 63,
    slug: "qmd-luyano",
  },
  {
    id: 107,
    slug: "gg-mercado1",
  },
  {
    id: 111,
    slug: "jamones-del-rancho",
  },
  {
    id: 112,
    slug: "ggmayorista-banco",
  },
  {
    id: 113,
    slug: "la-moneda-cubana",
  },
  {
    id: 134,
    slug: "al-pirata",
  },
  {
    id: 146,
    slug: "bodegon-chacon",
  },
  {
    id: 144,
    slug: "casa-matriz-chacon",
  },
  {
    id: 140,
    slug: "la-nitida-del-siria",
  },
  {
    id: 139,
    slug: "mercadito-casino",
  },
  {
    id: 138,
    slug: "dubai",
  },
  {
    id: 123,
    slug: "mac-win",
  },
  {
    id: 115,
    slug: "LEAX_Shop",
  },
  {
    id: 114,
    slug: "la-tentacion",
  },
  {
    id: 321,
    slug: "el-mio-cm",
  },
  {
    id: 322,
    slug: "el-mio-colon",
  },
  {
    id: 319,
    slug: "hostal-alex113",
  },
  {
    id: 320,
    slug: "casa-matriz-yagrumas",
  },
  {
    id: 318,
    slug: "el-mio",
  },
  {
    id: 327,
    slug: "crispi-chicken",
  },
  {
    id: 326,
    slug: "appledroid",
  },
  {
    id: 17,
    slug: "negocio-de-prueba",
  },
  {
    id: 328,
    slug: "bodegon-martinez",
  },
  {
    id: 323,
    slug: "d&c-import-export",
  },
  {
    id: 324,
    slug: "cubarato",
  },
  {
    id: 325,
    slug: "nitro-wheels",
  },
  {
    id: 350,
    slug: "zodi",
  },
  {
    id: 351,
    slug: "guinera-42",
  },
  {
    id: 352,
    slug: "amor-de-casa",
  },
  {
    id: 353,
    slug: "alissa",
  },
  {
    id: 354,
    slug: "mi-nina",
  },
  {
    id: 355,
    slug: "gelatodev",
  },
];

export const regions = {
  "https://api.tecopos.com/api": [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40,
    41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59,
    60, 61, 62, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81,
    82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
    101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 114, 116,
    117, 118, 119, 120, 198, 255, 256, 257, 251, 208, 226, 285, 282, 213, 288,
    289, 194, 351, 317,
  ],
  "https://api2.tecopos.com/api": [
    121, 122, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135, 136,
    137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150, 151,
    152, 153, 154, 155, 156, 157, 158, 159, 161, 162, 163, 164, 165, 167, 168,
    169, 200, 197, 192, 179, 189,
  ],
  "https://api3.tecopos.com/api": [
    170, 171, 172, 173, 174, 175, 176, 177, 178, 180, 181, 182, 183, 184, 185,
    186, 187, 188, 190, 191, 195, 196, 199, 201, 202, 203, 204, 205, 206, 207,
    208, 209, 210, 211, 212, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223,
    224, 225, 227, 228, 229, 230, 231, 232, 269, 3, 115, 236, 170, 171, 172,
    173, 174, 175, 176, 177, 178, 180, 181, 182, 183, 184, 185, 186, 187, 188,
    190, 191, 195, 196, 199, 201, 202, 203, 204, 205, 206, 207, 209, 210, 211,
    212, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 227, 228,
    229, 230, 231, 232, 269, 166, 3, 115, 330, 293, 305, 123, 160, 65, 350, 193,
    64, 63, 352, 353, 354,
  ],
};
