import React from "react";
import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import APIServer from "./APIServer";
import {
  set_availableCurrencies,
  set_minimun_amount_to_buy_with_delivery,
  set_products_with_no_salesCategories,
  setBusiness,
  setBusinessId,
  setCurrentCurrency,
  setOnSaleProducts,
  setProducts,
  setSaleCategories,
  setSuggestedProducts,
  setUser,
} from "../store/slices/SessionSlice";
import {
  ProductInterface,
  StoreSections,
} from "../interfaces/ServerInterfaces";
import { setSearchInputValue } from "../store/slices/resultsReducer";

interface initialLoadInterface {
  slug: string | undefined;
  set_online_shop_main_currency: React.Dispatch<
    React.SetStateAction<
      {
        value: string;
        name: string;
        href: string;
        current: boolean;
      }[]
    >
  >;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setfirstLoad: React.Dispatch<React.SetStateAction<boolean>>;
  setPageNotFound404: React.Dispatch<React.SetStateAction<boolean>>;
}

const useInitialLoad = () => {
  const { search } = useLocation();
  const dispatch = useAppDispatch();

  const { key: userKey, currentCurrency } = useAppSelector(
    (state) => state.session
  );

  const reFetchProducts = async (slug: string | undefined) => {
    try {
      const response = await APIServer.get(
        `/public/products/business/${slug}?all_data=true`
      );
      const products = response.data.products;

      dispatch(
        setSuggestedProducts(
          products.filter((prod: ProductInterface) => prod.suggested)
        )
      );
      dispatch(
        setOnSaleProducts(
          products.filter((prod: ProductInterface) => prod.onSale)
        )
      );

      let store_sections: Array<StoreSections> = [];
      let products_without_salesCategories: StoreSections = {
        title: "SIN CATEGORÍA",
        id: 777,
        data: [],
      };

      products
        .filter(
          (prod: ProductInterface) =>
            prod.showWhenOutStock ||
            prod.totalQuantity > 0 ||
            prod.type === "SERVICE"
        )
        .forEach((item: ProductInterface) => {
          const found = store_sections.find(
            (section) => section.id === item.salesCategory?.id
          );

          if (found) {
            found.data.push(item);
          } else {
            store_sections.push({
              title: item.salesCategory?.name || "SIN CATEGORÍA",
              id: item.salesCategory?.id || 777,
              data: [item],
            });
          }
        });

      store_sections = store_sections
        .filter((item) => item.data.length > 0)
        .sort((a, b) => {
          return a?.title?.toUpperCase() > b?.title?.toUpperCase() ? 1 : -1;
        });

      dispatch(setProducts(store_sections));
      dispatch(
        set_products_with_no_salesCategories(products_without_salesCategories)
      );
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  const initialLoad = async ({
    slug,
    set_online_shop_main_currency,
    setIsLoading,
    setfirstLoad,
    setPageNotFound404,
  }: initialLoadInterface) => {
    await Promise.all([
      APIServer.get(`/public/business/${slug}`).then((resp) => {
        return resp;
      }),
      APIServer.get(`/public/categories/${slug}`),
      APIServer.get(`/public/products/business/${slug}?all_data=true`).then(
        (resp) => {
          return resp;
        }
      ),
    ])
      .then((resp) => {
        if (userKey === null) {
          dispatch(setUser(null));
        }

        const onlineShopMainCurrency =
          resp[0].data.configurationsKey
            .find(
              (config: { key: string }) =>
                config.key === "online_shop_main_currency"
            )
            ?.value.split(",") ?? [];

        dispatch(set_availableCurrencies(resp[0]?.data?.availableCurrencies));

        let minimun_amount_to_buy_with_delivery =
          JSON.parse(
            resp[0].data.configurationsKey.find(
              (config: { key: string }) =>
                config.key === "minimun_amount_to_buy_with_delivery"
            )?.value
          ) ?? [];

        if (Object.keys(minimun_amount_to_buy_with_delivery).length === 0) {
          minimun_amount_to_buy_with_delivery = [];
        }

        dispatch(
          set_minimun_amount_to_buy_with_delivery(
            minimun_amount_to_buy_with_delivery
          )
        );

        let setMainCurrencies = onlineShopMainCurrency.map(
          (config: any, index: number) => ({
            value: config,
            name: config,
            href: "#",
            current: currentCurrency === config,
          })
        );

        if (
          setMainCurrencies.filter((curr: { current: boolean }) => curr.current)
            .length === 0
        ) {
          setMainCurrencies[0].current = true;
        }

        dispatch(
          setCurrentCurrency(
            setMainCurrencies.find((curr: { current: boolean }) => curr.current)
              .value
          )
        );

        set_online_shop_main_currency(setMainCurrencies);

        dispatch(setBusiness(resp[0].data));
        dispatch(setBusinessId(resp[0].data.id));
        dispatch(
          setSuggestedProducts(
            resp[2].data.products.filter(
              (prod: ProductInterface) => prod.suggested
            )
          )
        );
        dispatch(
          setOnSaleProducts(
            resp[2].data.products.filter(
              (prod: ProductInterface) => prod.onSale
            )
          )
        );

        let store_sections: Array<StoreSections> = [];
        let products_without_salesCategories: StoreSections = {
          title: "SIN CATEGORÍA",
          id: 777,
          data: [],
        };

        resp[2].data.products
          .filter(
            (prod: ProductInterface) =>
              prod.showWhenOutStock ||
              prod.totalQuantity > 0 ||
              prod.type === "SERVICE"
          )
          .forEach((item: ProductInterface) => {
            //Find  if  category exist
            const found = store_sections.find(
              (section) => section.id === item.salesCategory?.id
            );

            if (found) {
              store_sections = store_sections.map((item_data) => {
                if (item_data.id === item.salesCategory?.id) {
                  return {
                    ...item_data,
                    data: [...item_data.data, item],
                  };
                }
                return item_data;
              });
            } else {
              if (item?.salesCategory !== null) {
                store_sections.push({
                  id: item.salesCategory?.id,
                  title: item.salesCategory?.name,
                  data: [item],
                });
              } else {
                products_without_salesCategories.data.push(item);
              }
            }
          });

        store_sections = store_sections
          .filter((item) => item.data.length > 0)
          .sort((a, b) => {
            return a?.title?.toUpperCase() > b?.title?.toUpperCase() ? 1 : -1;
          });

        dispatch(
          setSaleCategories(
            resp[1].data.filter((category: { id: any }) => {
              if (
                store_sections.filter(
                  (store: { id: any }) => store.id === category.id
                ).length >= 1
              ) {
                return category;
              }
            })
          )
        );

        dispatch(
          set_products_with_no_salesCategories(products_without_salesCategories)
        );
        dispatch(setProducts(store_sections));
        setIsLoading(false);

        if (search !== "") {
          dispatch(
            setSearchInputValue(
              search.split("?search=")[1]?.replaceAll("%20", " ")
            )
          );
        }

        setfirstLoad(false);
      })
      .catch(() => {
        setIsLoading(false);
        setPageNotFound404(true);
      });
  };

  return { initialLoad, reFetchProducts };
};

export default useInitialLoad;
